export const Modal = {
  parts: ['header'],
  variants: {
    danger: {
      header: {
        bg: 'white',
      },
    },
    legacy: {
      header: {
        borderEndRadius: '0',
        borderStartRadius: '0',
        borderRadius: '0',
        bg: 'brand.500',
        fontSize: '20px',
        textAlign: 'center',
      },
    },
    marketplace: {
      header: {
        borderRadius: 'unset',
      },
      borderRadius: '2xl',
      boxShadow: 'lg',
      bg: 'var(--chakra-colors-background-container-light)',
      _dark: {
        bg: 'var(--chakra-colors-background-container-dark)',
      },
    },
    clickThrough: {
      borderRadius: '2xl',
      boxShadow: 'lg',
      bg: 'var(--chakra-colors-background-container-light)',
      _dark: {
        bg: 'var(--chakra-colors-background-container-dark)',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
      dialog: {
        pointerEvents: 'auto',
      },
    },
  },
  defaultProps: {
    variant: 'legacy',
  },
}
