export const styles = {
  global: {
    body: {
      bg: 'var(--chakra-colors-background-global-light)',
      font: 'var(--nabis-font-body)',
      minWidth: '0px',
    },
    // Override for Semantic UI global styling.
    'a[class^="chakra"]:hover': {
      color: 'inherit',
    },
  },
}
