import { FormControl, FormHelperText, FormLabel, Select, SelectProps } from '@chakra-ui/react'
import React, { useState } from 'react'

export const FetchDataDropdown: React.FC<
  {
    id: string
    isLoadingMessage?: string
    label: string
    helperText?: string
    placeholder: string
    setData: any
    hook?: any
    data?: {
      data: any
    }
    state: any
    sortBy?: string
  } & SelectProps
> = ({ id, data, label, helperText, placeholder, setData, hook, isLoadingMessage, sortBy, state }) => {
  // const useLicensedLocations = UseLicensedLocations()
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [, setError] = useState(null)

  async function loadData() {
    const result = hook ? await hook : { data }
    return result
  }

  if (!response) {
    loadData().then((res) => {
      const { isError, error } = res
      if (res?.data?.data) {
        const dropdownOptions = sortBy
          ? res.data.data.sort((a, b) => {
              if (typeof a[sortBy] === 'string') {
                return a[sortBy].trim().localeCompare(b[sortBy].trim())
              }
              return a[sortBy].localeCompare(b[sortBy])
            })
          : res.data.data
        setResponse(dropdownOptions)
      }

      setIsLoading(res.isLoading)
      if (isError) {
        setError(error)
      }
    })
  }

  return (
    <FormControl id={id}>
      <FormLabel textStyle="captionSmall" marginBottom="6px">
        {label}
      </FormLabel>

      {response && !isLoading && (
        <Select
          fontSize="0.875rem"
          placeholder={placeholder}
          onChange={(e) =>
            setData({
              field: id as any,
              value: e.target.value,
            })
          }
          value={state[id]}
        >
          {response.map((location) => {
            return <option value={location.id}>{location.name}</option>
          })}
        </Select>
      )}

      {isLoading && (isLoadingMessage || 'Loading ...')}

      {helperText && (
        <FormHelperText textStyle="captionSmall" color="#999999">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
