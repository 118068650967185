import { Box, Heading, Flex, VStack } from '@chakra-ui/react'
import React from 'react'
import { NabisLogoBlackUpdated } from '@nabis/images'

export const Wrapper: React.FC<{ headingText: string }> = ({ headingText, children }) => {
  const isMobile = window.innerWidth < 488
  const logoMarginLeft = isMobile ? '-60%' : '-72%'

  return (
    <Flex alignItems={'center'} justify={'center'}>
      <VStack width="90%" maxWidth="488px" paddingBottom={'4rem'}>
        <Box marginLeft={logoMarginLeft} marginTop={'15vh'} marginBottom={'15px'}>
          <Box float={'left'} width="129px" height="27px">
            <NabisLogoBlackUpdated />
          </Box>
        </Box>

        <Box backgroundColor="white" borderRadius="16px" boxShadow="lg" padding={6}>
          <Box width="100%" marginBottom={4}>
            <Heading
              fontSize="20px"
              textStyle="containerTitle"
              color={'text.base.light'}
              fontWeight={'600'}
              lineHeight={'135%'}
            >
              {headingText}
            </Heading>
          </Box>
          {children}
        </Box>
      </VStack>
    </Flex>
  )
}
