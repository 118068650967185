import React from 'react'

import { Text, TextProps } from '@chakra-ui/react'

export const TableHeaderText: React.FC = (props: TextProps) => {
  return (
    <Text
      textStyle="captionSmall"
      color="#999999"
      textTransform="none"
      letterSpacing="initial"
      fontWeight="initial"
      paddingLeft="0px"
      paddingRight="0px"
      {...props}
    />
  )
}
