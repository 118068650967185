export const Textarea = {
  baseStyle: {
    textStyle: 'body',
    color: 'var(--chakra-colors-text-base-light)',
    _disabled: {
      color: 'var(--chakra-colors-text-tertiary-light)',
    },
    _placeholder: {
      color: 'var(--chakra-colors-text-secondary-light)',
    },

    _dark: {
      _disabled: {
        color: 'var(--chakra-colors-text-tertiary-dark)',
      },
      _placeholder: {
        color: 'var(--chakra-colors-text-secondary-dark)',
      },
    },
  },
  sizes: {
    xs: {
      field: {
        textStyle: 'caption',
      },
    },
  },

  variants: {
    outline: {
      borderRadius: 'var(--chakra-radii-base)',
      bg: 'var(--chakra-colors-background-form-default-light)',
      borderColor: 'var(--chakra-colors-border-form-default-light)',
      _hover: {
        boxShadow: 'none',
        borderColor: 'var(--chakra-colors-border-form-hover-light)',
      },
      _focus: {
        boxShadow: 'none',
        borderColor: 'var(--chakra-colors-border-form-focus)',
      },
      _invalid: {
        boxShadow: 'none',
        borderColor: 'var(--chakra-colors-border-form-critical-light) !important',
      },
      _dark: {
        color: 'var(--chakra-colors-text-base-dark)',
        bg: 'var(--chakra-colors-background-form-default-dark)',
        borderColor: 'var(--chakra-colors-border-form-default-dark)',
        _hover: {
          boxShadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-hover-dark)',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-focus)',
        },
        _invalid: {
          boxShadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-critical-dark) !important',
        },
      },
    },
  },
}
/*
  Example of usage: <Textarea>

  - Props -
  variants=
    outlined 

  default variant - outlined 

  size=
    'xs' | 'sm' | 'md' | 'lg'

  Ex:
   1) Basic:
       <Textarea placeholder='Here is a sample placeholder' size='xs' />

   @props: 
   https://chakra-ui.com/docs/form/textarea
*/
