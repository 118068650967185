export const typography = {
  lineHeights: {
    normal: 'normal',
    none: '1em',
    shorter: '1.1em',
    short: '1.25em',
    base: '1.35em',
    tall: '1.45em',
    taller: '2em',
  },
  // TODO update in the font PR
  fonts: {
    body: 'var(--nabis-font-family)',
    heading: 'var(--nabis-font-family)',
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,'Liberation Mono','Courier New',monospace`,
  },
  fontSizes: {
    '3xl': '1.672rem',
  },
  textStyles: {
    pageTitle: {
      'font-size': '1.75rem',
      fontWeight: '600',
      lineHeight: '1.35em',
    },
    containerTitle: {
      'font-size': '1.25rem',
      fontWeight: '600',
      lineHeight: '1.35em',
    },
    sectionHeading: {
      'font-size': '1rem',
      fontWeight: '600',
      lineHeight: '1.35em',
    },
    sectionHeadingCaps: {
      'font-size': '1rem',
      fontWeight: '600',
      lineHeight: '1.35em',
      textTransform: 'uppercase',
    },
    displaySmall: {
      'font-size': '1rem',
      fontWeight: '400',
      lineHeight: '1.35em',
    },
    displayMedium: {
      'font-size': '1.25rem',
      fontWeight: '400',
      lineHeight: '1.35em',
    },
    displayLarge: {
      'font-size': '1.5rem',
      fontWeight: '400',
      lineHeight: '1.35em',
    },
    badge: {
      'font-size': '0.625rem',
      fontWeight: '600',
      lineHeight: '1em',
    },
    body: {
      'font-size': '0.875rem',
      fontWeight: '400',
      lineHeight: '1.35em',
    },
    bodyStrong: {
      'font-size': '0.875rem',
      fontWeight: '600',
      lineHeight: '1.35em',
    },
    caption: {
      'font-size': '0.875rem',
      fontWeight: '400',
      lineHeight: '1.35em',
    },
    captionSmall: {
      'font-size': '0.75rem',
      fontWeight: '400',
      lineHeight: '1.35em',
    },
    menuGroupTitle: {
      fontSize: '0.75rem',
      fontWeight: '600',
      lineHeight: '1.35em',
    },
    textWrapMobileButton: {
      fontSize: '.875rem',
      whiteSpace: ['normal', 'nowrap'],
      height: ['auto', 'default'],
    },
  },
} as const
