import { createBreakpoints } from '@chakra-ui/theme-tools'

/**
 * Breakpoints for responsive design
 */
export const breakpoints = createBreakpoints({
  sm: '30rem', // TODO change back to 20rem // 320px
  md: '48rem', // 768px
  lg: '64rem', // 1024px
  xl: '90rem', // 1440px
  '2xl': '105rem', // 1680px
})
