import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

import { Modal } from '@nabis/ui/theme/components/Modal'
import { Heading } from '@nabis/ui/theme/components/Heading'
import { ColoredLabel } from '@nabis/ui/components/ColoredLabel'
import { Badge } from './components/Badge'
import { Button } from './components/Button'
import { Card } from './components/Card'
import { Divider } from './components/Divider'
import { Checkbox } from './components/Checkbox'
import { Radio } from './components/Radio'
import { Menu } from './components/Menu'
import { CloseButton } from './components/CloseButton'
import { Stack } from './components/Stack'
import { Switch } from './components/Switch'
import { Table } from './components/Table'
import { Tabs } from './components/Tabs'
import { Tag } from './components/Tag'
import { Text } from './components/Text'
import { Textarea } from './components/TextArea'
import { Select } from './components/Select'
import { Input } from './components/Input'
import { NumberInput } from './components/NumberInput'
import { FlexVariant } from './components/FlexVariant'
import { DataTable } from './components/DataTable'

import { styles } from './styles'

import { foundations } from './foundations'

export const NabisTheme = {
  ...foundations,
  components: {
    Badge,
    Button,
    Card,
    Checkbox,
    CloseButton,
    ColoredLabel,
    Divider,
    FlexVariant,
    Heading,
    Input,
    NumberInput,
    Menu,
    Modal,
    Radio,
    Stack,
    Select,
    Switch,
    DataTable,
    Table,
    Tabs,
    Tag,
    Text,
    Textarea,
  },
  styles,
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
} as const

export const theme = extendTheme(NabisTheme, withDefaultColorScheme({ colorScheme: 'brand' }))
