const tagBaseStyles = {
  // Tag
  container: {
    bg: 'var(--chakra-colors-border-transparent-light)',
    color: 'var(--chakra-colors-text-secondary-light)',
    fontWeight: '400',

    _dark: {
      bg: 'var(--chakra-colors-border-transparent-dark)',
      color: 'var(--chakra-colors-text-secondary-dark)',
    },

    svg: { strokeWidth: '1px' },
  },
  // TagLabel
  label: {
    color: 'var(--chakra-colors-text-secondary-light)',
    _dark: { color: 'var(--chakra-colors-text-secondary-dark)' },
    fontWeight: '400',
  },
  // TagCloseButton
  closeButton: {
    color: 'var(--chakra-colors-text-secondary-light)',
    _dark: { color: 'var(--chakra-colors-text-secondary-dark)' },
    fontSize: '0.875rem',
  },
}

export const Tag = {
  baseStyle: tagBaseStyles,

  // 'sm', 'md', 'lg'
  sizes: {
    sm: {
      container: { padding: '0.25rem 0.5rem', textStyle: 'caption' },
      label: { textStyle: 'caption' },
      closeButton: { fontSize: '0.875rem', marginLeft: '0.5rem' },
    },
    md: {
      container: { padding: '0.25rem 0.5rem', textStyle: 'body' },
      label: { textStyle: 'body' },
      closeButton: { fontSize: '0.875rem', marginLeft: '0.5rem' },
    },
    lg: {
      container: { padding: '0.5rem 0.875rem', textStyle: 'body' },
      label: { textStyle: 'body' },
      closeButton: { fontSize: '1rem', marginLeft: '0.5rem' },
    },
  },

  // 'rounded', 'pill'
  variants: {
    rounded: {
      container: { borderRadius: 'sm' },
    },
    pill: {
      container: { borderRadius: 'full' },
    },
  },

  defaultProps: {
    variant: 'rounded',
    size: 'md',
  },
}

/**
@part <Tag> | <TagLabel> | <TagCloseButton /> | <TagLeftIcon />
 
@prop variant = 'rounded' | 'pill'
@prop size = 'sm' | 'md' | 'lg'

@example
  1. Default
    <Tag size='sm' variant='rounded'>
      Small
    </Tag>
  2. with Icon
    <Tag size='md' variant='rounded'>
      <TagLeftIcon boxSize='12px' mr='4px' as={PhoneIcon} />
      <TagLabel>Icon</TagLabel>
    </Tag>
  3. with Close icon
    <Tag size='lg' variant='pill'>
      <TagLabel>Close</TagLabel>
      <TagCloseButton />
    </Tag>
  4. with Close icon and Icon
    <Tag size='sm' variant='pill'>
      <TagLeftIcon boxSize='12px' mr='4px' as={PhoneIcon} />
      <TagLabel>
        Close
      </TagLabel>
      <TagCloseButton />
    </Tag>
*/
