import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Global } from '@emotion/react'

import { theme } from './theme'
import { ComponentDefaultPropsOverride } from './helpers/ComponentDefaultPropsOverride'

interface NabisUIProviderProps {
  children: React.ReactNode
}

export const NabisUIProvider: React.FC<NabisUIProviderProps> = ({ children }) => {
  return (
    <ChakraProvider
      theme={theme}
      // Chakra CSS reset interacts with Semantic global styling, so ...
      resetCSS={false}
    >
      <ComponentDefaultPropsOverride />
      <Global
        // ... we use a custom CSS reset and comment out anything that may interfere with Semantic.
        styles={`
          html {
            font-size: 16px;
            // line-height: 1.5;
            -webkit-text-size-adjust: 100%;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            touch-action: manipulation;
            text-size-adjust: 100%;
          }
          body {
            position: relative;
            min-height: 100%;
            font-feature-settings: 'kern';
          }
          *,
          *::before,
          *::after {
            border-width: 0;
            border-style: solid;
            box-sizing: border-box;
          }
          main {
            display: block;
          }
          hr {
            border-top-width: 1px;
            box-sizing: content-box;
            height: 0;
            overflow: visible;
          }
          pre,
          code,
          kbd,
          samp {
            font-family: SFMono-Regular,  Menlo, Monaco, Consolas, monospace;
            font-size: 1em;
          }
          a {
            background-color: transparent;
            color: inherit;
            text-decoration: inherit;
          }
          abbr[title] {
            border-bottom: none;
            text-decoration: underline;
            -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
          }
          b,
          strong {
            font-weight: bold;
          }
          small {
            font-size: 80%;
          }
          sub,
          sup {
            font-size: 75%;
            line-height: 0;
            position: relative;
            vertical-align: baseline;
          }
          sub {
            bottom: -0.25em;
          }
          sup {
            top: -0.5em;
          }
          img {
            border-style: none;
          }
          button,
          input,
          optgroup,
          select,
          textarea {
            font-family: inherit;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;
          }
          button,
          input {
            overflow: visible;
          }
          button,
          select {
            text-transform: none;
          }
          button::-moz-focus-inner,
          [type='button']::-moz-focus-inner,
          [type='reset']::-moz-focus-inner,
          [type='submit']::-moz-focus-inner {
            border-style: none;
            padding: 0;
          }
          fieldset {
            padding: 0.35em 0.75em 0.625em;
          }
          legend {
            box-sizing: border-box;
            color: inherit;
            display: table;
            max-width: 100%;
            padding: 0;
            white-space: normal;
          }
          progress {
            vertical-align: baseline;
          }
          textarea {
            overflow: auto;
          }
          [type='checkbox'],
          [type='radio'] {
            box-sizing: border-box;
            padding: 0;
          }
          [type='number']::-webkit-inner-spin-button,
          [type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
          [type='search'] {
            -webkit-appearance: textfield;
            outline-offset: -2px;
          }
          [type='search']::-webkit-search-decoration {
            -webkit-appearance: none !important;
          }
          ::-webkit-file-upload-button {
            -webkit-appearance: button;
            font: inherit;
          }
          details {
            display: block;
          }
          summary {
            display: list-item;
          }
          template {
            display: none;
          }
          [hidden] {
            display: none !important;
          }
          body,
          blockquote,
          dl,
          dd,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          hr,
          figure,
          p,
          pre {
            margin: 0;
          }
          button {
            background: transparent;
            padding: 0;
            font-size: 0.875rem;
          }
          fieldset {
            margin: 0;
            padding: 0;
          }
          ol,
          ul {
            margin: 0;
            padding: 0;
          }
          textarea {
            resize: vertical;
          }
          button,
          [role='button'] {
            cursor: pointer;
          }
          button::-moz-focus-inner {
            border: 0 !important;
          }
          table {
            border-collapse: collapse;
          }
          // h1,
          // h2,
          // h3,
          // h4,
          // h5,
          // h6 {
          //   font-size: inherit;
          //   font-weight: inherit;
          // }
          button,
          input,
          optgroup,
          select,
          textarea {
            padding: 0;
            line-height: inherit;
            // color: inherit;
          }
          img,
          svg,
          video,
          canvas,
          audio,
          iframe,
          embed,
          object {
            display: block;
          }
          img,
          video {
            max-width: 100%;
            height: auto;
          }
          [data-js-focus-visible] :focus:not([data-focus-visible-added]) {
            outline: none;
            box-shadow: none;
          }
          select::-ms-expand {
            display: none;
          }

          // Semantic UI overrides

          // Buttons
          .ui.button {
            transition: all 250ms;
            background-color: var(--chakra-colors-grey-400);
            color: var(--chakra-colors-white);
            font-size: 0.875rem;

            &:hover, &:active, &:focus {
              background-color: var(--chakra-colors-grey-300);
              color: var(--chakra-colors-white);
            }
          }

          // Primary buttons
          .ui.button.positive,
          .ui.button.green,
          .ui.button.yellow {
            background-color: var(--chakra-colors-brand-500);
            color: var(--chakra-colors-black);

            &:hover, &:active, &:focus {
              background-color: var(--chakra-colors-brand-600);
              color: var(--chakra-colors-black);
            }
          }

          // Secondary buttons
          .ui.button.blue {
            background-color: var(--chakra-colors-grey-900);
            color: var(--chakra-colors-white);

            &:hover, &:active, &:focus {
              background-color: var(--chakra-colors-grey-700);
              color: var(--chakra-colors-white);
            }
          }

          // Outlined buttons
          .ui.button.basic.green {
            box-shadow: 0 0 0 1px var(--chakra-colors-brand-500) !important;
            color: var(--chakra-colors-brand-500) !important;

            &:hover, &:active, &:focus {
              box-shadow: 0 0 0 1px var(--chakra-colors-brand-600) !important;
              color: var(--chakra-colors-brand-600) !important;
            }
          }

          // Font updates
          .ui.table,
          .ui.small.table,
          .ui.mini.buttons .button, 
          .ui.mini.buttons .or, 
          .ui.mini.button,
          .ui.buttons .button, 
          .ui.buttons .or, 
          .ui.button, 
          .ui.menu,
          .ui.form,
          .ui.checkbox .box, .ui.checkbox label,
          .ui.dropdown .menu > .item,
          .ui.blue.label, .ui.blue.labels .label,
          .ui.small.buttons .button, .ui.small.buttons .or, .ui.small.button {
            font-size: 0.875rem;
          }

          .ui.table.green {
            border-top-color: var(--chakra-colors-brand-500);
          }

          .ui.pagination a {
            color: var(--chakra-colors-black) !important;

            &:hover, &:active, &:focus {
              background-color: var(--chakra-colors-brand-50) !important;
            }
          }

          // Labels
          .ui.label.green,
          .ui.label.ribbon.green {
            left: calc(-1.2rem * 1.5);
            background-color: var(--chakra-colors-brand-500) !important;
            border-color: var(--chakra-colors-brand-600) !important;
            color: var(--chakra-colors-black) !important;
          }

          // Modals
          .ui.modal > .header {
            background-color: var(--chakra-colors-brand-500) !important;
            stroke: var(--chakra-colors-black); // For SVG icons.

            * {
              color: var(--chakra-colors-black) !important;
            }
          }

          // Steps
          .ui.steps .step.active .title {
            color: var(--chakra-colors-brand-500) !important;
          }

        `}
      />
      {children}
    </ChakraProvider>
  )
}
