import React, { useMemo, useState } from 'react'
import { Checkbox, Box, Link, Stack, Text, VStack } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import { UseLicensedLocationQuery } from 'hooks'
import { Wrapper } from '../Wrapper'
import { usePaymentContext } from '../context'
import { PaymentDetailsInput as Input } from './PaymentDetailsInput'
import { FetchDataDropdown } from '../../../components/FetchDataDropdown'
import { AccountType, NABIS_ACH_LEGAL_LINK } from '../../../constants'
import { CreatePaymentButton as SubmitPaymentGoToReceiptButton } from './CreatePaymentButton'
import { PaymentDetailsState } from '../types'

const defaultState: PaymentDetailsState = {
  accountNumber: null,
  accountType: null,
  confirmAccountNumber: null,
  email: null,
  firstName: null,
  hasAcceptedTerms: null,
  lastName: null,
  licensedLocationId: null,
  routingNumber: null,
}

export const PaymentDetailsPage = () => {
  const context = usePaymentContext()

  const [state, setState] = useState(defaultState)

  const isMobile = window.innerWidth < 488

  const total = useMemo(() => {
    return context.state.invoices.reduce((acc, current) => {
      const { amount } = current
      acc += amount
      return acc
    }, 0)
  }, [context.state.invoices])

  const setField = (payload) => {
    const { field, value } = payload
    const newState = cloneDeep(state)
    newState[field] = value
    setState(newState)
  }

  const types = () => {
    return {
      data: Object.keys(AccountType).map((key) => {
        return { id: key, name: AccountType[key] }
      }),
    }
  }

  return (
    <Wrapper headingText="Payment Details">
      <Box
        borderTop="1px solid rgba(0, 0, 0, 0.1)"
        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
        paddingY={4}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {isMobile ? (
          <Text textStyle="captionSmall" color="#999999">
            Your bank account will be <br /> debited a total of
          </Text>
        ) : (
          <Text textStyle="captionSmall" color="#999999">
            Your bank account will be debited a total of
          </Text>
        )}

        <Text textStyle="bodyStrong" whiteSpace="nowrap" paddingLeft={3}>
          <Text id="total-payment" color={'text.base.light'} fontSize={'14px'} fontWeight={'400'} lineHeight={'135%'}>
            ${total}
          </Text>
        </Text>
      </Box>

      <Box borderBottom="1px solid rgba(0, 0, 0, 0.1)" paddingY={6}>
        <Box marginBottom={3}>
          <Text textStyle="sectionHeading">Billing Details</Text>
        </Box>
        <VStack spacing={4}>
          <Stack direction={['column', 'row']} spacing={3} width="100%">
            <Input id="firstName" label="First Name" placeholder="John" setData={setField} state={state} />
            <Input id="lastName" label="Last Name" placeholder="Doe" setData={setField} state={state} />
          </Stack>

          <Input
            id="email"
            label="Email"
            type="email"
            placeholder="john@example.com"
            setData={setField}
            state={state}
          />

          <FetchDataDropdown
            id="licensedLocationId"
            label="Associated Business"
            placeholder="Select an org / licensed entity"
            helperText="If you're paying on behalf of multiple locations, please submit separate payments for each specific location."
            hook={UseLicensedLocationQuery()}
            setData={setField}
            state={state}
            sortBy="name"
          />
        </VStack>
      </Box>
      <Box borderBottom="1px solid rgba(0, 0, 0, 0.1)" paddingY={6}>
        <Box marginBottom={3}>
          <Text textStyle="sectionHeading">Payment Details</Text>
        </Box>
        <VStack spacing={4}>
          <Input
            id="routingNumber"
            type="number"
            label="Bank ACH Routing Number"
            placeholder="1234567890"
            setData={setField}
            state={state}
          />

          <FetchDataDropdown
            id="accountType"
            label="Account Type"
            placeholder="Select an option"
            data={types()}
            setData={setField}
            state={state}
          />

          <Stack direction={['column', 'row']} spacing={3} width="100%">
            <Input
              id="accountNumber"
              type="number"
              label="Account Number"
              placeholder="1234567890"
              setData={setField}
              state={state}
            />
            <Input
              id="confirmAccountNumber"
              type="number"
              label="Confirm Account Number"
              placeholder="1234567890"
              setData={setField}
              state={state}
            />
          </Stack>
        </VStack>
      </Box>
      <Box marginTop={5}>
        <Text textStyle="captionSmall">
          By checking the box and clicking the 'Pay Now' button below, you are providing your electronic signature (as
          the deposit account holder on behalf of the Company identified above as the deposit account holder of the
          business account identified above), and you are authorizing QuickFee or its payment processing agent to
          initiate a one-time electronic fund transfer in the form of an ACH debit from the deposit account in the
          amount stated above.
        </Text>
      </Box>
      <Box marginTop={3} marginBottom={6}>
        <Checkbox
          isChecked={state.hasAcceptedTerms}
          onChange={(e) => setField({ field: 'hasAcceptedTerms', value: e.target.checked })}
        >
          <Text textStyle="captionSmall">
            I accept the{' '}
            <Link color="text.hyperlink.light" href={NABIS_ACH_LEGAL_LINK} target="_blank" rel="noopener noreferrer">
              Nabis ACH Terms
            </Link>
            .
          </Text>
        </Checkbox>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <SubmitPaymentGoToReceiptButton
          paymentDetailsState={state}
          orders={context.state.invoices}
          notes={context.state.notes}
          label={'Pay Now'}
        />
      </Box>
    </Wrapper>
  )
}
