import React, { useRef } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
} from '@chakra-ui/react'

import { X } from 'react-feather'

interface ErrorOverlayInterface {
  onClose: any
  title: string
  message: string
}
export const ErrorOverlay = ({ onClose, title, message }: ErrorOverlayInterface) => {
  const cancelRef = useRef()
  return (
    <AlertDialog isOpen={true} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>{message}</AlertDescription>
            &nbsp;{' '}
            <Icon
              float="right"
              role="button"
              as={X}
              onClick={() => {
                onClose()
              }}
              ref={cancelRef}
            />
          </Alert>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
