import React from 'react'
import { Box, Button, Container, Divider, Link, Text, HStack, VStack } from '@chakra-ui/react'
import { ArrowRight } from 'react-feather'
import { Wrapper } from '../Wrapper'
import { usePaymentContext } from '../context'
import { MonolithLinks } from '../../../constants'

export const ReceiptPage = () => {
  const context = usePaymentContext()
  const containerBackground = 'background.nestedContainer.light'
  const textColor = '#999999'
  const arEmail = 'ar@nabis.com'
  const { bankAccountSuffix, orders, total } = context.state.receiptData

  return (
    <Wrapper headingText="Thank you for your payment.">
      <Container bg={containerBackground} paddingRight="0px" borderRadius="16px">
        <Box paddingTop="14px" paddingBottom="19px">
          <VStack align="left">
            <Text color={textColor} fontWeight="400" lineHeight="135%" fontSize="12px">
              Payment received via ACH &middot;&middot;&middot;&middot; {bankAccountSuffix}
            </Text>

            <Text color="text.black" fontSize="16px" lineHeight="135%" fontWeight="600">
              ${total}
            </Text>

            <Divider marginTop="11px" marginBottom="11px" color="1px solid rgba(0, 0, 0, 0.1)" />

            <Text color={textColor} marginBottom="4px" fontSize="12px">
              Applied to orders
            </Text>

            {orders &&
              orders.map((order) => {
                return (
                  <HStack alignItems={'top'} padding={'.75rem 0'} borderTop={'1px solid rgba(0, 0, 0, 0.1)'}>
                    <Box w="70%" alignItems={'top'} margin="0 0 11 0">
                      <Text w="100%" fontSize="14px" fontWeight="600" float={'left'} color="text.black">
                        {order.orderNumber}
                      </Text>

                      <Text fontSize="12px" float={'left'} color={textColor}>
                        {order.organizationName}
                      </Text>
                    </Box>

                    <Box w="30%" alignItems={'right'} paddingRight="11px">
                      <Text fontWeight="600" fontSize="14px" color="text.black" float={'right'}>
                        ${order.transactionAmount}
                      </Text>
                    </Box>
                  </HStack>
                )
              })}
          </VStack>
        </Box>
      </Container>

      <VStack align="left">
        <Text
          color={textColor}
          fontSize="12px"
          lineHeight={'135%'}
          fontWeight="400"
          marginTop="16px"
          marginBottom="24px"
        >
          A receipt for this payment has been emailed to the administrators for this retail location. Please allow up to
          three business days for this payment to process.
        </Text>

        <Text color="text.dark" fontSize="14px" fontWeight="600" marginTop="0px">
          Questions?
        </Text>

        <Text fontSize="12px" paddingBottom="24px">
          Contact the AR team at{' '}
          <Link color="text.hyperlink.light" href={`mailto:${arEmail}`}>
            ar@nabis.com
          </Link>
        </Text>

        <Divider color="1px solid rgba(0, 0, 0, 0.1)" />

        <HStack paddingTop="21px" align={'top'}>
          <Box w="100%">
            <Text fontSize="12px" color="text.dark" fontWeight="400">
              Already have a free Nabis account? Sign in to view payables, and track orders via the Nabis Tracker.
            </Text>
          </Box>
        </HStack>

        <HStack align={'top'} paddingTop="5px">
          <Box w="100%">
            <Link href={MonolithLinks.SIGNIN}>
              <Button variant="primarySolid" size={'sm'}>
                Sign in &nbsp; <ArrowRight height={'12px'} width={'12px'} />
              </Button>
            </Link>
          </Box>
        </HStack>
      </VStack>
    </Wrapper>
  )
}
