export const Card = {
  baseStyle: {
    px: '4',
    py: '4',
    rounded: 'md',
    borderWidth: '1px',
    bg: 'white',
  },
  variants: {
    brand: {
      bg: 'brand.500',
      color: 'black',
    },
    secondary: {
      bg: 'secondary',
      color: 'white',
    },
  },
}
