export const CloseButton = {
  baseStyle: {
    bg: 'transparent',
    color: 'var(--chakra-colors-text-base-light)',
    border: 'none',

    _dark: { color: 'var(--chakra-colors-text-base-dark)' },

    _hover: {
      bg: 'var(--chakra-colors-interactive-hover-light)',
      _dark: { bg: 'var(--chakra-colors-interactive-hover-dark)' },
    },

    _active: {
      bg: 'var(--chakra-colors-interactive-pressed-light)',
      _dark: { bg: 'var(--chakra-colors-interactive-pressed-dark)' },
    },

    _disabled: {
      bg: 'transparent',
      color: 'var(--chakra-colors-text-tertiary-dark)',
      _dark: { bg: 'transparent', color: 'var(--chakra-colors-text-tertiary-dark)' },
    },
  },

  // size: 'sm' | 'md' | 'lg'
  sizes: {
    sm: {
      height: '1.5rem',
      width: '1.5rem',
    },
    md: {
      height: '2rem',
      width: '2rem',
    },
    lg: {
      height: '2.5rem',
      width: '2.5rem',
    },
  },

  defaultProps: {
    size: 'sm',
  },
}

/**  Example of usage

@prop {string} size = 'sm' | 'md' | 'lg'
@prop {boolean} isLoading
@prop {boolean}  isDisabled

@example <CloseButton size='lg' />
*/
