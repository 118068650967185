import { Text } from '@chakra-ui/react'
import React from 'react'

/**
 * This component overrides default props for things that aren't themable
 * For example making all text components of type 'span' by default
 */
export const ComponentDefaultPropsOverride: React.FC = () => {
  Text.defaultProps = {
    as: 'span',
  } as any
  return <></>
}
