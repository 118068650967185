export const Button = {
  baseStyle: {
    borderRadius: 'base',
    fontSize: '0.875rem',
  },
  // Sizes: 'xs' | 'sm' | 'md' | 'lg'
  sizes: {
    xs: ({ variant }) => {
      const isIconButton = variant?.toLowerCase?.()?.includes?.('icon')
      return {
        textStyle: 'caption',
        padding: '.25rem .5rem',
        ...(isIconButton && {
          height: '1.25rem',
          width: '1.25rem',
          minWidth: '1.25rem',
        }),
        svg: {
          ...(isIconButton && { fontSize: '0.875rem', width: '0.875rem', height: '0.875rem', strokeWidth: '1px' }),
        },
      }
    },
    sm: ({ variant }) => {
      const isIconButton = variant?.toLowerCase?.()?.includes?.('icon')
      return {
        textStyle: 'body',
        padding: '.375rem .75rem',
        ...(isIconButton && {
          height: '2rem',
          width: '2rem',
          minWidth: '2rem',
        }),
        svg: { ...(isIconButton && { fontSize: '1rem', width: '1rem', height: '1rem', strokeWidth: '1px' }) },
      }
    },
    md: ({ variant }) => {
      const isIconButton = variant?.toLowerCase?.()?.includes?.('icon')
      return {
        textStyle: 'body',
        padding: '.5rem .75rem',
        ...(isIconButton && {
          height: '2.5rem',
          width: '2.5rem',
          minWidth: '2.5rem',
        }),
        svg: { ...(isIconButton && { fontSize: '1.25rem', width: '1.25rem', height: '1.25rem', strokeWidth: '2px' }) },
      }
    },
    lg: ({ variant }) => {
      const isIconButton = variant?.toLowerCase?.()?.includes?.('icon')
      return {
        textStyle: 'body',
        padding: '.75rem 1rem',
        ...(isIconButton && {
          height: '2.75rem',
          width: '2.75rem',
          minWidth: '2.75rem',
        }),
        svg: { ...(isIconButton && { fontSize: '1.25rem', width: '1.25rem', height: '1.25rem', strokeWidth: '2px' }) },
      }
    },
  },

  // Variants:
  // primarySolid | secondarySolid | destructiveSolid | tertiarySolid
  // primaryOutline | secondaryOutline | destructiveOutline
  // primaryGhosted | secondaryGhosted | destructiveGhosted
  variants: {
    /* Solid variants */
    // Primary
    primarySolid: {
      bg: 'var(--chakra-colors-button-primary-default-light)',
      color: 'var(--chakra-colors-text-black)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-button-primary-default-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-button-primary-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-primary-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-button-primary-focus-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-primary-focus-dark)',
        },
      },
      _disabled: {
        bg: 'var(--chakra-colors-button-primary-disabled-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-disabled-dark)',
        },
      },
    },
    // Secondary
    secondarySolid: {
      bg: 'var(--chakra-colors-button-secondary-default-light)',
      color: 'var(--chakra-colors-text-base-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-button-secondary-default-dark)',
        color: 'var(--chakra-colors-text-base-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-button-secondary-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-secondary-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-button-secondary-focus-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-secondary-focus-dark)',
        },
      },
      _disabled: {
        bg: 'var(--chakra-colors-button-secondary-disabled-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-disabled-dark)',
        },
      },
    },
    // Destructive
    destructiveSolid: {
      bg: 'var(--chakra-colors-button-destroy-default)',
      color: 'var(--chakra-colors-text-white)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _hover: {
        bg: 'var(--chakra-colors-button-destroy-hover)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },
      _active: {
        bg: 'var(--chakra-colors-button-destroy-focus)',
      },
      _disabled: {
        bg: 'var(--chakra-colors-button-destroy-disabled-light)',
      },
    },
    // Tertiary
    tertiarySolid: {
      bg: 'var(--chakra-colors-button-secondary-default-dark)',
      color: 'var(--chakra-colors-text-white)',
      border: '1px solid var(--chakra-colors-border-transparent-dark)',

      _dark: {
        bg: 'var(--chakra-colors-button-secondary-default-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-button-secondary-hover-dark)',

        _dark: {
          bg: 'var(--chakra-colors-button-secondary-hover-light)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-button-secondary-focus-dark)',

        _dark: {
          bg: 'var(--chakra-colors-button-secondary-focus-light)',
        },
      },
      _disabled: {
        bg: 'var(--chakra-colors-button-secondary-focus-dark)',

        _dark: {
          bg: 'var(--chakra-colors-button-secondary-focus-light)',
        },
      },
    },

    /* Outline variants */
    // Primary
    primaryOutline: {
      bg: 'transparent',
      color: 'var(--chakra-colors-text-hyperlink-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        color: 'var(--chakra-colors-text-hyperlink-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-pressed-dark)',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'var(--chakra-colors-text-tertiary-light)',

        _dark: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
      },
    },
    // Secondary
    secondaryOutline: {
      bg: 'transparent',
      color: 'var(--chakra-colors-text-base-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        color: 'var(--chakra-colors-text-base-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-pressed-dark)',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'var(--chakra-colors-text-tertiary-light)',

        _dark: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
      },
    },
    // Destructive
    destructiveOutline: {
      bg: 'transparent',
      color: 'var(--chakra-colors-text-critical)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        color: 'var(--chakra-colors-text-critical)',
      },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-pressed-dark)',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'var(--chakra-colors-text-tertiary-light)',

        _dark: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
      },
    },

    /* Ghosted variants */
    // Primary
    primaryGhosted: {
      bg: 'transparent',
      color: 'var(--chakra-colors-text-hyperlink-light)',
      border: 'none',

      _dark: {
        color: 'var(--chakra-colors-text-hyperlink-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-pressed-dark)',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'var(--chakra-colors-text-tertiary-light)',

        _dark: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
      },
    },
    // Secondary
    secondaryGhosted: {
      bg: 'transparent',
      color: 'var(--chakra-colors-text-base-light)',
      border: 'none',

      _dark: {
        color: 'var(--chakra-colors-text-base-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-pressed-dark)',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'var(--chakra-colors-text-tertiary-light)',

        _dark: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
      },
    },
    // Destructive
    destructiveGhosted: {
      bg: 'transparent',
      color: 'var(--chakra-colors-text-critical)',
      border: 'none',

      _dark: {
        color: 'var(--chakra-colors-text-critical)',
      },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-pressed-dark)',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'var(--chakra-colors-text-tertiary-light)',

        _dark: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
      },
    },

    primary: {
      bg: 'brand.500',
      color: 'black',
      _hover: {
        bg: 'brand.600',
      },
    },
    secondary: {
      bg: 'grey.400',
      color: 'black',
      _hover: {
        bg: 'grey.500',
      },
    },
    tertiary: {
      bg: 'white',
      color: 'black',
      _hover: {
        bg: 'white',
      },
      border: '1px solid rgba(0, 0, 0, 0.1)',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
    },
    primaryWhiteText: {
      fontWeight: 'bold',
      px: '20px',
      paddingX: '20px',
      bg: 'brand.500',
      color: 'white',
      // Fix for: if a button is disabled and user hovers over, then button disappears
      _hover: {
        _disabled: {
          bg: 'brand.500',
        },
      },
    },
    primaryWhiteTextWashed: {
      fontWeight: 'bold',
      px: '20px',
      paddingX: '20px',
      bg: 'brand.400',
      color: 'white',
      // Fix for: if a button is disabled and user hovers over, then button disappears
      _hover: {
        _disabled: {
          bg: 'brand.400',
        },
      },
    },

    secondaryWhiteText: {
      fontWeight: 'bold',
      px: '20px',
      paddingX: '20px',
      bg: 'grey.900',
      color: 'white',
      // Fix for: if a button is disabled and user hovers over, then button disappears
      _hover: {
        _disabled: {
          bg: 'secondary',
        },
      },
    },
    onfleet: {
      fontWeight: 'bold',
      px: '20px',
      paddingX: '20px',
      bg: 'onfleet',
      color: 'white',
      // Fix for: if a button is disabled and user hovers over, then button disappears
      _hover: {
        _disabled: {
          bg: 'onfleet',
        },
      },
    },
    danger: {
      fontWeight: 'bold',
      px: '20px',
      paddingX: '20px',
      bg: 'red',
      color: 'white',
      // Fix for: if a button is disabled and user hovers over, then button disappears
      _hover: {
        _disabled: {
          bg: 'red',
        },
      },
    },
    evidence: {
      fontWeight: 'bold',
      px: '20px',
      paddingX: '20px',
      bg: 'none',
      maxW: '50px',
      h: '40px',
      fontSize: '10px',
    },
    nav: {
      bg: 'none',
      color: 'grey.600',
      borderRadius: 0,
      _hover: {
        color: 'grey.600',
        bg: 'grey.200',
      },
    },

    /* <IconButton> VARIANTS */
    // Variants: iconSolid | iconOutline | iconGhosted

    // solid
    iconSolid: {
      bg: 'var(--chakra-colors-button-secondary-default-light)',
      color: 'var(--chakra-colors-icon-default)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-button-secondary-default-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-button-secondary-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-secondary-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-button-secondary-focus-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-secondary-focus-dark)',
        },
      },
      _disabled: {
        bg: 'var(--chakra-colors-button-secondary-disabled-light)',

        _dark: {
          bg: 'var(--chakra-colors-button-disabled-dark)',
        },
      },
    },

    // outline
    iconOutline: {
      bg: 'transparent',
      color: 'var(--chakra-colors-icon-default)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        color: 'var(--chakra-colors-icon-default)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-pressed-dark)',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'var(--chakra-colors-text-tertiary-light)',

        _dark: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
      },
    },

    // ghosted
    iconGhosted: {
      bg: 'transparent',
      color: 'var(--chakra-colors-icon-default)',
      border: 'none',

      _dark: {
        color: 'var(--chakra-colors-icon-default)',
      },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-hover-dark)',
        },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',

        _dark: {
          bg: 'var(--chakra-colors-interactive-pressed-dark)',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'var(--chakra-colors-text-tertiary-light)',

        _dark: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
      },
    },
  },
}

/*
  Example of usage: <Button>

  - Props -
  variants=
    primarySolid | secondarySolid | destructiveSolid
    primaryOutline | secondaryOutline | destructiveOutline
    primaryGhosted | secondaryGhosted | destructiveGhosted

  size=
    'xs' | 'sm' | 'md' | 'lg'

  isLoading | loadingText='Loading' | disabled

  leftIcon | rightIcon = <Icon boxSize='12px'>
    - boxSize for xs, sm, md : 12px
    - boxSize for lg: 14px

  Ex:
    <Button isLoading loadingText='Loading' size='lg' variant='primaryOutline'>
      Button
    </Button>

    with icon
    <Button size='lg' variant='primarySolid' leftIcon={<EditIcon boxSize='14px' />}>
      Edit
    </Button>
*/

/**
  @template IconButton

* @prop {string} variant : required
* @prop {string} colorSchema
* @prop {boolean} isActive
* @prop {boolean} isDisabled | disabled
* @prop {boolean} isRound
* @prop {boolean} isLoading | loading
* @prop {React.ReactElement} icon : required
* @prop {React.ReactElement} spinner

* @prop {variant}:
  - iconSolid
  - iconOutline
  - iconGhosted

* @prop {size}:
  - xs
  - sm
  - md
  - lg

@returns {React.ReactElement}

Usage:
  <IconButton
    aria-label='airplay'
    icon={<Feather.Airplay />} 'you can use feather icons or chakra icons'
    variant='iconOutline'
    size='sm'
    isRound
  />
*/
