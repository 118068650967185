import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import { Invoice } from '../types'

enum Action {
  SET_INVOICE_DATA = 'SET_INVOICE_DATA',
  SET_RECEIPT_DATA = 'SET_RECEIPT_DATA',
}

type InvoiceData = {
  invoices: Invoice[]
  notes?: string
}

type ReceiptOrders = {
  organizationName: string
  orderNumber: number
  transactionAmount: number | string
}

type ReceiptData = {
  total: number | string
  orders: ReceiptOrders[]
  bankAccountSuffix: string
}

type SetInvoiceData = { type: Action.SET_INVOICE_DATA; payload: InvoiceData }

type SetReceiptData = { type: Action.SET_RECEIPT_DATA; payload: ReceiptData }

type ActionTypes = SetInvoiceData | SetReceiptData

export const initialState: State = {
  invoices: [],
  receiptData: {
    total: 0,
    orders: [],
    bankAccountSuffix: '',
  },
}

interface State {
  invoices: Invoice[]
  notes?: string
  receiptData: ReceiptData
}

export const actions = (dispatch: React.Dispatch<ActionTypes>) => ({
  setInvoiceData: (payload: SetInvoiceData['payload']) =>
    dispatch({
      type: Action.SET_INVOICE_DATA,
      payload,
    }),
  setReceiptData: (payload: SetReceiptData['payload']) =>
    dispatch({
      type: Action.SET_RECEIPT_DATA,
      payload,
    }),
})

export const reducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case Action.SET_INVOICE_DATA: {
      const newState = cloneDeep(state)
      newState.invoices = action.payload.invoices
      newState.notes = action.payload.notes
      return newState
    }

    case Action.SET_RECEIPT_DATA: {
      const newState = cloneDeep(state)
      newState.receiptData = action.payload
      return newState
    }

    default:
      return state
  }
}

export interface UsePaymentReducer {
  state: State
  dispatch: React.Dispatch<ActionTypes>
  actions: ReturnType<typeof actions>
}
