import React, { useState } from 'react'
import { ArrowRight } from 'react-feather'
import { cloneDeep, isEmpty, uniqBy } from 'lodash'
import { Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { usePaymentContext } from './context'
import { ErrorOverlay } from '../../components'
import { BillPayRoutes } from '../../constants'

interface ContinueButtonInterface {
  state: any
}

export const ContinueButton = ({ state }: ContinueButtonInterface) => {
  const context = usePaymentContext()
  const router = useHistory()
  const [errorMessage, setErrorMessage] = useState(null)

  // wipe data in reducer if it exists to ensure data is not stale
  if (context.state.invoices.length > 0) {
    context.actions.setInvoiceData({ invoices: [], notes: undefined })
  }

  const disableButton = () => {
    if (isEmpty(state.invoices)) {
      return true
    }
    // check for empty invoice numbers and amounts
    return Object.keys(state.invoices).some((key) => {
      const { amount, number } = state.invoices[key]
      return !amount || !number
    })
  }

  const duplicateInvoices = (invoices: { number: number; amount: number }[]) => {
    return invoices.length !== uniqBy(invoices, 'number').length
  }

  const invalidAmount = (invoices: { number: number; amount: number }[]) => {
    return !invoices.every((i) => /^\d+(\.\d{0,2})?$/.test(String(i.amount)))
  }

  const setDataAndNavigateToNextPage = () => {
    const newState = cloneDeep(state)

    const invoices = Object.keys(newState.invoices).map((key) => {
      const invoice = newState.invoices[key]
      const { number, amount } = invoice
      return { number, amount }
    })

    // validation
    if (duplicateInvoices(invoices)) {
      setErrorMessage({
        error: 'Invoice Invalid',
        message: 'Duplicate invoice numbers are not allowed',
      })
      return
    }

    if (invalidAmount(invoices)) {
      setErrorMessage({
        error: 'Amount Invalid',
        message: 'Payment amount has invalid format. Example format: 100.10',
      })
      return
    }

    const payload = {
      invoices,
      notes: state.notes,
    }

    context.actions.setInvoiceData(payload) // set data for payment-details view
    router.push(BillPayRoutes.PAYMENT.DETAILS)
  }

  return (
    <>
      {errorMessage?.error && (
        <ErrorOverlay
          title={errorMessage?.error}
          message={errorMessage.message}
          onClose={() => {
            setErrorMessage(null)
          }}
        />
      )}

      <Button
        id="add-note"
        disabled={disableButton()}
        onClick={setDataAndNavigateToNextPage}
        size={'sm'}
        fontSize={'12px'}
        style={{ padding: '6px, 12px, 6px, 12px' }}
        variant="primarySolid"
      >
        Continue &nbsp;
        <ArrowRight height={'12px'} width={'12px'} />
      </Button>
    </>
  )
}
