// lowest level API layer - talks directly to server
import { BILL_PAY_BACKEND } from '../constants'

export async function CreatePayment(data) {
  const result = await fetch(`${BILL_PAY_BACKEND}/create-payment`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  return result.json()
}
