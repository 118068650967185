export const Badge = {
  baseStyle: {
    fontFamily: 'var(--nabis-font-family)',
    bg: 'var(--chakra-colors-accent-yellow-bg-light)',
    color: 'var(--chakra-colors-accent-yellow-fg-light)',
    borderRadius: '0.125rem',
    padding: '0.25rem',
    textStyle: 'badge',
    fontSize: '0.625rem',
  },
  variants: {
    yellow: {
      bg: 'var(--chakra-colors-accent-yellow-bg-light)',
      color: 'var(--chakra-colors-accent-yellow-fg-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-accent-yellow-bg-dark)',
        color: 'var(--chakra-colors-accent-yellow-fg-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },
    },
    red: {
      bg: 'var(--chakra-colors-accent-red-bg-light)',
      color: 'var(--chakra-colors-accent-red-fg-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-accent-red-bg-dark)',
        color: 'var(--chakra-colors-accent-red-fg-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },
    },
    blue: {
      bg: 'var(--chakra-colors-accent-blue-bg-light)',
      color: 'var(--chakra-colors-accent-blue-fg-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-accent-blue-bg-dark)',
        color: 'var(--chakra-colors-accent-blue-fg-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },
    },
    orange: {
      bg: 'var(--chakra-colors-accent-orange-bg-light)',
      color: 'var(--chakra-colors-accent-orange-fg-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-accent-orange-bg-dark)',
        color: 'var(--chakra-colors-accent-orange-fg-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },
    },
    green: {
      bg: 'var(--chakra-colors-accent-green-bg-light)',
      color: 'var(--chakra-colors-accent-green-fg-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-accent-green-bg-dark)',
        color: 'var(--chakra-colors-accent-green-fg-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },
    },
    gray: {
      bg: 'var(--chakra-colors-accent-red-bg-light)',
      color: 'var(--chakra-colors-accent-gray-fg-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-accent-gray-bg-dark)',
        color: 'var(--chakra-colors-accent-gray-fg-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },
    },
    black: {
      bg: 'var(--chakra-colors-accent-black-bg-light)',
      color: 'var(--chakra-colors-accent-black-fg-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-accent-gray-bg-dark)',
        color: 'var(--chakra-colors-accent-gray-fg-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },
    },
  },
  defaultProps: {
    variant: 'yellow',
  },
}
/*
  Example of usage: <Badge>

  !Uppercase for everything 

  - Props -
  variants=
    yellow | red | blue
    orange | green | gray
  
  default = subtle | solid | outline

  Example:
    <Badge variant='green'>In design</Badge>

*/
