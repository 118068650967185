import * as React from 'react'
import { NabisUIProvider } from '@nabis/ui/Provider'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import {
  actions,
  initialState as PaymentContextInitialState,
  reducer,
  UsePaymentReducer,
} from 'modules/Payment/reducer'
import { AddInvoicesPage } from 'modules/Payment/AddInvoicesPage'
import { PaymentDetailsPage } from 'modules/Payment/Details/PaymentDetailsPage'
import { ReceiptPage } from 'modules/Payment/Receipt/ReceiptPage'
import { ErrorBoundary } from 'components'
import { BillPayRoutes } from './constants'

// context has to be initialized outside of App to avoid syntax errors
export const PaymentContext = React.createContext<UsePaymentReducer>({} as UsePaymentReducer)

class PaymentContextProvider extends React.Component<{ state; dispatch; actions }, any> {
  render() {
    return (
      <PaymentContext.Provider
        value={{
          state: this.props.state,
          dispatch: this.props.dispatch,
          actions: this.props.actions,
        }}
      >
        {this.props.children}
      </PaymentContext.Provider>
    )
  }
}

export const App = () => {
  const [state, dispatch] = React.useReducer(reducer, PaymentContextInitialState)

  return (
    <ErrorBoundary>
      <NabisUIProvider>
        <BrowserRouter>
          <PaymentContextProvider state={state} dispatch={dispatch} actions={actions(dispatch)}>
            <Route exact path={BillPayRoutes.PAYMENT.ADD_INVOICES} component={AddInvoicesPage} />
            <Route exact path={BillPayRoutes.PAYMENT.DETAILS} component={PaymentDetailsPage} />
            <Route exact path={BillPayRoutes.PAYMENT.RECEIPT} component={ReceiptPage} />
            <Route exact path="/">
              <Redirect to={BillPayRoutes.PAYMENT.ADD_INVOICES} />
            </Route>
          </PaymentContextProvider>
          {/* <Route path='/' exact component={AuthSwitch} />
              <Route path='*' component={PageNotFound} /> */}
        </BrowserRouter>
      </NabisUIProvider>
    </ErrorBoundary>
  )
}
