export const Switch = {
  baseStyle: {
    track: {
      bg: 'var(--chakra-colors-border-form-default-light)',
      _dark: { bg: 'var(--chakra-colors-border-form-default-dark)' },

      _checked: {
        bg: 'var(--chakra-colors-interactive-activate)',
        _dark: { bg: 'var(--chakra-colors-interactive-activate)' },
      },

      _disabled: {
        opacity: '0.5',
        _dark: { opacity: '0.2' },
      },
    },
  },
}

/**
  Example of usage:

@prop {string} aria-describedby
@prop {boolean} aria-invalid
@prop {string} aria-label
@prop {string} aria-labelledby
@prop {boolean} defaultChecked
@prop {boolean} defaultIsChecked
@prop {string} id
@prop {boolean} isChecked
@prop {boolean} isDisabled
@prop {boolean} isFocusable
@prop {boolean} isInvalid
@prop {boolean} isReadOnly
@prop {boolean} isRequired
@prop {string} name
@prop {function} onBlur
@prop {string} onChange
@prop {function} onFocus
@prop {string} size = 'sm' | 'md' | 'lg'
@prop {string} spacing
@prop {number} tabIndex
@prop {string | number} value

@example <Switch isChecked={true} isDisabled={true} size='md' />
*/
