const headersTextBase = {
  color: 'var(--chakra-colors-text-secondary-light)',
  textTransform: 'none',
  textStyle: 'caption',
  fontSize: '0.75rem',
  flex: '1',
}

export const DataTable = {
  parts: [
    'container',
    'header',
    'tableContainer',
    'search',
    'searchInputGroup',
    'searchInput',
    'filters',
    'slider',
    'headers',
    'headersText',
    'tableMenuContainer',
    'tableMenuDropdownCell',
    'tableMenuDropdownMenuItem',
    'tableMenuDropdownMenuItemOption',
    'dataRows',
    'dataRowsText',
    'footer',
    'footerText',
  ],
  baseStyle: {
    container: { bg: 'transparent' },
    header: { mb: '1rem' },
    tableContainer: {
      width: '100%',
      bg: 'var(--chakra-colors-background-container-light)',
      _dark: {
        bg: 'var(--chakra-colors-background-container-dark)',
      },
    },
    search: {
      marginBottom: '1rem',
      marginTop: '0',
      width: '100%',
      maxWidth: '595px',
      height: '37px',
    },
    searchInputGroup: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '35px',
      width: '100%',
      border: '1px solid var(--chakra-colors-border-form-default-light)',
      borderRadius: 'base',
      backgroundColor: 'var(--chakra-colors-background-form-default-light)',
    },
    searchInput: {
      height: '35px',
      textStyle: 'body',
      outlineColor: 'transparent',
      color: 'var(--chakra-colors-text-secondary-light)',
    },
    filters: {
      flex: 1,
      textAlign: 'right',
      alignItems: 'center',
      marginBottom: '1rem',
      marginLeft: '1rem',
    },
    headers: {
      px: 'var(--nabis-base-padding-x)',
      py: 'var(--nabis-base-padding-y)',
      borderColor: 'var(--chakra-colors-border-opaque-light)',
    },
    headersText: {
      ...headersTextBase,
    },
    sortableHeadersText: {
      ...headersTextBase,
      cursor: 'pointer',
    },
    tableMenuDropdownCell: {
      px: '0',
      width: '.75rem',
      height: '.75rem',
      borderColor: 'var(--chakra-colors-border-opaque-light)',
      color: 'var(--chakra-colors-text-secondary-light)',
      svg: {
        color: 'var(--chakra-colors-text-secondary-light)',
        stroke: 'var(--chakra-colors-text-secondary-light)',
      },
    },
    bulkSelectCell: {
      px: '0',
      borderColor: 'var(--chakra-colors-border-opaque-light)',
      color: 'var(--chakra-colors-text-secondary-light)',
      textAlign: 'center',
      width: '.6rem',
      svg: {
        color: 'var(--chakra-colors-text-secondary-light)',
        stroke: 'var(--chakra-colors-text-secondary-light)',
      },
      label: {
        ml: '1rem',
        mt: '3px',
      },
    },
    tableMenuContainer: {
      lineHeight: '1em',
      textAlign: 'center',
      display: 'block',
      textStyle: 'caption',
    },
    tableMenuDropdownMenuItem: {
      svg: {
        width: '1.25rem',
        height: '1.25rem',
      },
    },
    tableMenuDropdownMenuItemOption: {
      ml: 2,
    },
    dataRows: {
      px: 'var(--nabis-base-padding-x)',
      py: 'var(--nabis-base-padding-y)',
      borderColor: 'var(--chakra-colors-border-opaque-light)',
    },
    dataRowsText: {
      color: 'var(--chakra-colors-text-base-light)',
      textStyle: 'body',
    },
    dataRowsLabelText: {
      color: 'var(--chakra-colors-text-base-light)',
      textStyle: 'body',
      fontWeight: '600',
    },
    footer: {
      bg: 'var(--chakra-colors-background-nestedContainer-light)',
      borderColor: 'var(--chakra-colors-border-opaque-light)',
      color: 'var(--chakra-colors-text-secondary-light)',
      captionSide: 'bottom',
      boxShadow: 'var(--chakra-shadows-container)',
      textAlign: 'left',
      px: 'var(--nabis-base-padding-x)',
      py: 'var(--nabis-base-padding-y)',
    },
    footerText: {
      color: 'var(--chakra-colors-text-secondary-light)',
      textStyle: 'caption',
      fontSize: '0.75rem',
    },
    footerBulkActionsButton: {
      color: 'text.hyperlink.light',
      fontSize: '0.75rem',
      fontWeight: '600',
    },
  },
  variants: {
    standard: {},
    inline: {},
  },
}
