export enum AccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export const BILL_PAY_BACKEND = process.env.REACT_APP_BILL_PAY_BACKEND_URL
export const MONOLITH_UI = process.env.REACT_APP_MONOLITH_UI_URL

// currently a google doc
export const NABIS_ACH_LEGAL_LINK = 'https://docs.google.com/document/d/1EFGCS7A_eCeyFivzMy3FauK1Z2JZ46cg3pkCg8LPBNg'

export const MonolithLinks = {
  SIGNIN: `${MONOLITH_UI}/sign-in`,
}

export const BillPayRoutes = {
  PAYMENT: {
    ADD_INVOICES: '/payment',
    DETAILS: '/payment/details',
    RECEIPT: '/payment/receipt',
  },
}
