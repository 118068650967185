import { typography } from '../foundations/typography'

export const textColorVariants = {
  base: {
    color: 'var(--chakra-colors-text-base-light)',
    _dark: {
      color: 'var(--chakra-colors-text-base-dark)',
    },
  },
  secondary: {
    color: 'var(--chakra-colors-text-secondary-light)',
    _dark: {
      color: 'var(--chakra-colors-text-secondary-dark)',
    },
  },
  tertiary: {
    color: 'var(--chakra-colors-text-tertiary-light)',
    _dark: {
      color: 'var(--chakra-colors-text-tertiary-dark)',
    },
  },
  hyperlink: {
    color: 'var(--chakra-colors-text-hyperlink-light)',
    _dark: {
      color: 'var(--chakra-colors-text-hyperlink-dark)',
    },
  },
  critical: {
    color: 'var(--chakra-colors-text-critical)',
  },
  white: {
    color: 'var(--chakra-colors-text-white)',
  },
  black: {
    color: 'var(--chakra-colors-text-black)',
  },
}

export const Text = {
  defaultProps: {
    variant: 'base',
  },
  sizes: { ...typography.textStyles },
  variants: textColorVariants,
}
