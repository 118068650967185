export const layerStyles = {
  container: {
    boxShadow: '0px 2px 2px rgba(0,0,0,0.15)',
    borderRadius: 'base',
    bg: 'var(--chakra-colors-background-container-light)',
    _dark: {
      bg: 'var(--chakra-colors-background-container-dark)',
    },
  },
  nestedContainer: {
    borderRadius: 'base',
    bg: 'var(--chakra-colors-background-nested-container-light)',
    _dark: {
      bg: 'var(--chakra-colors-background-nested-container-dark)',
    },
  },
}
