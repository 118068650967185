export const FlexVariant = {
  variants: {
    'fill-viewport-container': {
      display: 'flex',
      flexDirection: 'column',
      pl: 4,
      pr: { base: 4, lg: 0 },
      py: 8,
      w: '100%',
      maxHeight: 'calc(100vh - 64px)',
      height: '100%',
      gridGap: 4,
    },
  },
}
