export const Checkbox = {
  baseStyle: {
    control: {
      bg: 'var(--chakra-colors-background-form-default-light)',
      border: '1px solid var(--chakra-colors-border-transparent-light)',

      _dark: {
        bg: 'var(--chakra-colors-background-form-default-dark)',
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
      },

      _checked: {
        bg: 'var(--chakra-colors-interactive-activate)',
        border: '1px solid var(--chakra-colors-border-transparent-light)',
        color: 'var(--chakra-colors-text-white)',

        _dark: {
          border: '1px solid var(--chakra-colors-border-transparent-dark)',
          bg: 'var(--chakra-colors-interactive-activate)',
        },

        _hover: { bg: 'var(--chakra-colors-interactive-activate)' },

        _disabled: {
          bg: 'var(--chakra-colors-background-form-disabled-light)',
          color: 'var(--chakra-colors-icon-default)',
          border: '1px solid var(--chakra-colors-border-form-default-light)',

          _dark: {
            bg: 'var(--chakra-colors-background-form-disabled-dark)',
            color: 'var(--chakra-colors-icon-default)',
            border: '1px solid var(--chakra-colors-border-form-default-dark)',
          },
        },
      },

      _invalid: { border: '1px solid var(--chakra-colors-border-form-critical-light)' },

      _disabled: {
        bg: 'var(--chakra-colors-background-form-disabled-light)',
        color: 'var(--chakra-colors-icon-default)',
        border: '1px solid var(--chakra-colors-border-form-default-light)',

        _dark: {
          bg: 'var(--chakra-colors-background-form-disabled-dark)',
          color: 'var(--chakra-colors-icon-default)',
          border: '1px solid var(--chakra-colors-border-form-default-dark)',
        },
      },
    },
  },

  sizes: {
    sm: {
      control: {
        width: '0.875rem',
        height: '0.875rem',
      },
    },
    md: {
      control: {
        width: '1rem',
        height: '1rem',
      },
    },
    lg: {
      control: {
        width: '1.25rem',
        height: '1.25rem',
      },
    },
  },
}

/**
  Example of usage:

@prop {string} aria-describedby
@prop {boolean} aria-invalid
@prop {string} aria-label
@prop {string} aria-labelledby
@prop {boolean} defaultChecked
@prop {boolean} defaultIsChecked
@prop {React.ReactElement} icon
@prop {string} iconColor
@prop {string | number} iconSize
@prop {string} id
@prop {boolean} isChecked
@prop {boolean} isDisabled
@prop {boolean} isFocusable
@prop {boolean} isIndeterminate
@prop {boolean} isInvalid
@prop {boolean} isReadOnly
@prop {boolean} isRequired
@prop {string} name
@prop {function} onBlur
@prop {string} onChange
@prop {function} onFocus
@prop {string} size = 'sm' | 'md' | 'lg'
@prop {string} spacing
@prop {number} tabIndex
@prop {string | number} value


@example
  <Checkbox isChecked={true} isDisabled={true} size='md' />
@example
  <CheckboxGroup defaultValue={['naruto', 'kakashi']}>
    <Stack spacing={[1, 5]} direction={['column', 'row']}>
      <Checkbox value='naruto'>Naruto</Checkbox>
      <Checkbox value='sasuke'>Sasuke</Checkbox>
      <Checkbox value='kakashi'>kakashi</Checkbox>
    </Stack>
  </CheckboxGroup>
*/
