import { typography } from '../foundations/typography'

export const Menu = {
  baseStyle: {
    // MenuList
    list: {
      boxShadow: 'var(--chakra-shadows-xl) !important',
      border: '1px solid var(--chakra-colors-border-transparent-light)',
      bg: 'var(--chakra-colors-background-container-light)',

      _dark: {
        border: '1px solid var(--chakra-colors-border-transparent-dark)',
        bg: 'var(--chakra-colors-background-container-dark)',
      },
    },

    // MenuItem
    item: {
      color: 'var(--chakra-colors-text-base-light)',
      textStyle: 'body',
      paddingY: '0.5rem',
      paddingLeft: '0.875rem',

      _dark: { color: 'var(--chakra-colors-text-base-dark)' },

      _hover: {
        bg: 'var(--chakra-colors-interactive-hover-light)',
        _dark: { bg: 'var(--chakra-colors-interactive-hover-dark)' },
      },
      _focus: {
        bg: 'var(--chakra-colors-interactive-hover-light)',
        _dark: { bg: 'var(--chakra-colors-interactive-hover-dark)' },
      },
      _active: {
        bg: 'var(--chakra-colors-interactive-pressed-light)',
        _dark: { bg: 'var(--chakra-colors-interactive-pressed-dark)' },
      },

      svg: {
        height: '1rem',
        width: '1rem',
        color: 'var(--chakra-colors-icon-default)',
        _dark: { color: 'var(--chakra-colors-icon-default)' },
      },
    },

    // MenuOptionGroup
    groupTitle: {
      textAlign: 'left',
      color: 'var(--chakra-colors-text-secondary-light)',
      margin: '0.5rem 0.875rem 0.25rem',
      textTransform: 'none',
      ...typography.textStyles.menuGroupTitle,
      _dark: { color: 'var(--chakra-colors-text-secondary-dark)' },
    },

    // command
    command: {
      textStyle: 'caption',
      color: 'var(--chakra-colors-text-secondary-light)',
      _dark: { color: 'var(--chakra-colors-text-secondary-dark)' },
    },

    // MenuDivider
    divider: {
      borderBottom: '1px solid',
      borderColor: 'var(--chakra-colors-border-transparent-light)',
      _dark: { borderColor: 'var(--chakra-colors-border-transparent-dark)' },
    },
  },
}

/**  
@example

MenuButton:
  // Customize MenuButton with Button conmponent:
  @prop {React.Element} as = <Button variant='primary' />

<Menu>
  <MenuButton as={<Button>}>
    Menu Example
  </MenuButton>
  <MenuList>
    <MenuGroup title='Menu Group Title'>
      <MenuItem icon={<DownloadIcon />}>Download</MenuItem>
      <MenuItem icon={<Feather.Airplay />}>Create a Copy</MenuItem>
    </MenuGroup>
    <MenuDivider />
    <MenuGroup title='Another Title'>
      <MenuItem command='⌘T'>Mark as Draft</MenuItem>
      <MenuItem  command='⌘N'>Delete</MenuItem>
    </MenuGroup>
    <MenuItem>Attend a Workshop</MenuItem>
  </MenuList>
</Menu>

Documentation: https://chakra-ui.com/docs/overlay/menu
*/
