export const Tabs = {
  baseStyle: {
    tab: {
      fontWeight: '600',
    },
  },
  sizes: {
    sm: ({ variant }) => ({
      tab: {
        textStyle: 'caption',
        padding: '0.25rem 0.875rem',
        ...(variant === 'line' && {
          padding: '0.25rem 0',
          marginRight: '0.875rem',
        }),
      },
    }),
    md: ({ variant }) => ({
      tab: {
        textStyle: 'body',
        padding: '0.5rem, 1rem',
        ...(variant === 'line' && {
          padding: '0.5rem 0',
          marginRight: '1rem',
        }),
      },
    }),
    lg: ({ variant }) => ({
      tab: {
        textStyle: 'body',
        padding: '0.875rem 1rem',
        ...(variant === 'line' && {
          padding: '0.875rem 0',
          marginRight: '1rem',
        }),
      },
    }),
  },

  variants: {
    // soft-rounded
    'soft-rounded': {
      tab: {
        _selected: {
          color: 'black',
        },
      },
    },

    // solid-rounded
    'solid-rounded': {
      // Tab
      tab: {
        color: 'var(--chakra-colors-text-base-light)',
        _dark: { color: 'var(--chakra-colors-text-base-dark)' },

        _selected: {
          color: 'var(--chakra-colors-text-black)',
          bg: 'var(--chakra-colors-services-nabis)',
          _dark: { color: 'var(--chakra-colors-text-black)' },

          _hover: {
            bg: 'var(--chakra-colors-services-nabis)',
            _dark: { bg: 'var(--chakra-colors-services-nabis)' },
          },
        },
        _hover: {
          bg: 'var(--chakra-colors-interactive-hover-light)',
          _dark: { bg: 'var(--chakra-colors-interactive-hover-dark)' },
        },
        _active: {
          bg: 'var(--chakra-colors-interactive-hover-light)',
          _dark: { bg: 'var(--chakra-colors-interactive-hover-dark)' },
        },
      },
    },

    // line
    line: {
      // TabList
      tablist: {
        borderColor: 'var(--chakra-colors-border-transparent-light)',
        borderBottomWidth: '2px',
        _dark: { borderColor: 'var(--chakra-colors-border-transparent-dark)' },
      },
      // Tab
      tab: {
        color: 'var(--chakra-colors-text-secondary-light)',
        _dark: { color: 'var(--chakra-colors-text-secondary-dark)' },

        _selected: {
          color: 'var(--chakra-colors-text-base-light)',
          borderColor: 'var(--chakra-colors-services-nabis)',
          borderBottomWidth: '2px',
          _dark: { color: 'var(--chakra-colors-text-base-dark)' },
        },
        _hover: {
          color: 'var(--chakra-colors-text-base-light)',
          _dark: { color: 'var(--chakra-colors-text-base-dark)' },
        },
        _active: {
          bg: 'none',
        },
      },
    },
  },
  defaultProps: {
    variant: 'line',
    size: 'md',
  },
}

/**  Example of usage

@prop {string} size = 'sm' | 'md' | 'lg'
@prop {string} variant = 'line' | 'soft-rounded' | 'soft-rounded'

@example
  <Tabs variant='solid-rounded' size={'sm'}>
    <TabList>
      <Tab>One</Tab>
      <Tab>Two</Tab>
      <Tab>Three</Tab>
    </TabList>
  </Tabs>
*/
