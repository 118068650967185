import { FormControl, FormHelperText, FormLabel, Input as ChakraInput, InputProps } from '@chakra-ui/react'
import React from 'react'

/*
 * setData is called it sets data on set by state[id]
 * example:
 * in page component
 * state = {
 *  firstName
 * }
 * in page component html
 * <PaymentDetailsInput id='firstName' />
 */
export const PaymentDetailsInput: React.FC<
  {
    id: string
    label: string
    helperText?: string
    setData: any
    state: any
  } & InputProps
> = ({ id, label, helperText, setData, state, ...rest }) => {
  return (
    <FormControl id={id}>
      <FormLabel textStyle="captionSmall" marginBottom="6px">
        {label}
      </FormLabel>
      <ChakraInput
        fontSize="0.875rem"
        padding={3}
        onChange={(e) =>
          setData({
            field: id as any,
            value: e.target.value,
          })
        }
        value={state[id]}
        {...rest}
      />
      {helperText && (
        <FormHelperText textStyle="captionSmall" color="#999999">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
