import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { layerStyles } from './layerStyles'
import { radii } from './radius'
import { shadows } from './shadows'
import { typography } from './typography'

/* Foundations for Chakra library styling
 * See: https://nabis.atlassian.net/wiki/spaces/ENGINEERIN/pages/1235517488/Theme+Variables#Fonts-&-Typography
 * For more information about how these variables were created
 */
export const foundations = {
  colors,
  ...typography,
  radii,
  breakpoints,
  shadows,
  layerStyles,
} as const
