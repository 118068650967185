import { typography } from '../foundations/typography'
import { textColorVariants } from './Text'

export const Heading = {
  sizes: { ...typography.textStyles }, // This is due to a bug in chakra ui here - https://github.com/chakra-ui/chakra-ui/issues/3884
  defaultProps: { variant: 'base' },
  variants: {
    app: {
      fontSize: '25px',
      textAlign: 'center',
    },
    ...textColorVariants,
  },
}
