import React from 'react'
import { Badge, BadgeProps, ThemeTypings } from '@chakra-ui/react'
import { startCase } from 'lodash'

interface Props extends BadgeProps {
  labels?: Record<string, ThemeTypings['colorSchemes']>
  value?: string
  variant?: 'subtle' | 'solid' | 'outline'
}

export const ColoredLabel: React.FC<Props> = ({ labels, value = '', variant = 'solid', ...rest }) => {
  const color: ThemeTypings['colorSchemes'] = labels?.[value] ?? 'grey'

  const titleize = (someValue: string) => {
    return startCase(someValue.toLowerCase())
  }
  return (
    <Badge
      colorScheme={color}
      variant={variant}
      textTransform="none"
      fontSize="md"
      py="4px"
      px="10px"
      whiteSpace="normal"
      children={titleize(value)}
      {...rest}
    />
  )
}
