import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { CreatePayment } from 'api/Payment'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Invoice, PaymentDetailsState } from '../types'
import { ErrorOverlay } from '../../../components'
import { usePaymentContext } from '../context'
import { BillPayRoutes } from '../../../constants'

interface CreatePaymentButtonInterface {
  paymentDetailsState: PaymentDetailsState
  orders: Invoice[]
  notes: string
  label: string
}

export const CreatePaymentButton = ({ label, paymentDetailsState, orders, notes }: CreatePaymentButtonInterface) => {
  const createPaymentMutation = useMutation(CreatePayment)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const context = usePaymentContext()
  const router = useHistory()

  // wipe data if it has already been set
  if (context.state.receiptData.orders.length > 0) {
    context.actions.setReceiptData({
      total: 0,
      orders: [],
      bankAccountSuffix: '',
    })
  }

  const emailIsInvalid = (email: string) => {
    const re = /\S+@\S+\.\S+/
    return !re.test(email)
  }

  const disableButton = () => {
    const hasEmptyFormValues = !Object.values(paymentDetailsState).every((x) => x !== null)
    return hasEmptyFormValues || isLoading
  }

  const receiptDto = (data) => {
    const { bankAccountSuffix, childrenTransactions, totalAmount } = data

    return {
      total: totalAmount,
      orders: childrenTransactions,
      bankAccountSuffix,
    }
  }

  async function triggerHook() {
    const { email } = paymentDetailsState
    const hasInvalidAccountNumber = paymentDetailsState.accountNumber !== paymentDetailsState.confirmAccountNumber
    // validation
    if (hasInvalidAccountNumber) {
      setErrorMessage({
        error: 'Account Invalid',
        message: '"Account Number" and "Confirm Account Number" must match',
      })
      return
    }

    if (emailIsInvalid(email)) {
      setErrorMessage({
        error: 'Email Invalid',
        message: 'Email formatting invalid. Here is an example of a valid email: "walterwhite@nabis.com"',
      })
      return
    }

    setIsLoading(true) // disable button to prevent numerous clicks

    const newBody = {
      ...paymentDetailsState,
      orders,
      notes: notes || '', // submit empty string if no notes were included
    }
    delete newBody.confirmAccountNumber
    delete newBody.hasAcceptedTerms

    await createPaymentMutation.mutate(newBody, {
      onSuccess: (data) => {
        const { error, message } = data
        if (error) {
          setErrorMessage({
            error,
            message,
          })
        } else {
          const payload = receiptDto(data)
          // set data and navigate to next page
          context.actions.setReceiptData(payload)
          router.push(BillPayRoutes.PAYMENT.RECEIPT)
        }
        setIsLoading(false) // re-enable pay now button
      },
    })
  }

  return (
    <>
      {errorMessage?.error && (
        <ErrorOverlay
          title={errorMessage?.error}
          message={errorMessage.message}
          onClose={() => {
            setErrorMessage(null)
          }}
        />
      )}

      <Button
        disabled={disableButton()}
        size="sm"
        variant="primarySolid"
        onClick={() => {
          triggerHook()
        }}
      >
        {label}
      </Button>
    </>
  )
}
