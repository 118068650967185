export const Divider = {
  variants: {
    solid: {
      borderColor: 'var(--chakra-colors-border-transparent-light)',
      _dark: {
        borderColor: 'var(--chakra-colors-border-transparent-dark)',
      },
    },
    dashed: {
      borderColor: 'var(--chakra-colors-border-transparent-light)',
      _dark: {
        borderColor: 'var(--chakra-colors-border-transparent-dark)',
      },
    },
    opaque: {
      borderStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: 'var(--chakra-colors-border-opaque-light)',
      _dark: {
        borderColor: 'var(--chakra-colors-border-opaque-dark)',
      },
    },
    defaultProps: {
      variant: 'solid',
    },
  },
}
/*
  Example of usage: <Divider>

  
  - Props -
  variants=
    solid | dashed | opaque
  

  Example:
    <Divider variant='opaque'/>

*/
