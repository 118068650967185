export const NumberInput = {
  baseStyle: {
    field: {
      textStyle: 'body',
      color: 'var(--chakra-colors-text-base-light)',
      _disabled: {
        color: 'var(--chakra-colors-text-tertiary-light)',
      },
      _placeholder: {
        color: 'var(--chakra-colors-text-secondary-light)',
      },

      _dark: {
        _disabled: {
          color: 'var(--chakra-colors-text-tertiary-dark)',
        },
        _placeholder: {
          color: 'var(--chakra-colors-text-secondary-dark)',
        },
      },
    },
  },
  sizes: {
    xs: {
      field: {
        textStyle: 'caption',
      },
    },
  },

  variants: {
    outline: {
      field: {
        borderRadius: 'var(--chakra-radii-base)',
        bg: 'var(--chakra-colors-background-form-default-light)',
        borderColor: 'var(--chakra-colors-border-form-default-light)',
        _hover: {
          shadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-hover-light)',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-focus)',
        },
        _invalid: {
          shadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-critical-light) !important',
        },
        _dark: {
          color: 'var(--chakra-colors-text-base-dark)',
          bg: 'var(--chakra-colors-background-form-default-dark)',
          borderColor: 'var(--chakra-colors-border-form-default-dark)',
          _hover: {
            shadow: 'none',
            borderColor: 'var(--chakra-colors-border-form-hover-dark)',
          },
          _focus: {
            shadow: 'none',
            borderColor: 'var(--chakra-colors-border-form-focus)',
          },
          _invalid: {
            shadow: 'none',
            borderColor: 'var(--chakra-colors-border-form-critical-dark) !important',
          },
        },
      },
      addon: {
        color: 'var(--chakra-colors-text-secondary-light)',
        bg: 'var(--chakra-colors-background-form-disabled-light)',
        borderColor: 'var(--chakra-colors-border-form-default-light)',
        borderRadius: 'var(--chakra-radii-base)',
        _dark: {
          bg: 'var(--chakra-colors-background-form-disabled-dark)',
          color: 'var(--chakra-colors-text-secondary-dark)',
          borderColor: 'var(--chakra-colors-border-form-default-dark)',
        },
      },
    },
    filled: {
      field: {
        color: 'var(--chakra-colors-text-base-light)',
        borderRadius: 'var(--chakra-radii-base)',
        bg: 'var(--chakra-colors-interactive-hover-light)',
        border: '1px solid',
        _focus: {
          bg: 'var(--chakra-colors-background-form-default-light) !important',
          borderColor: 'var(--chakra-colors-border-form-focus)',
        },
        _hover: {
          bg: 'var(--chakra-colors-interactive-pressed-light)',
        },
        _invalid: {
          borderColor: 'var(--chakra-colors-border-form-critical-light) !important',
          bg: 'var(--chakra-colors-background-form-critial-light)',
        },

        _dark: {
          color: 'var(--chakra-colors-text-base-dark)',
          bg: 'var(--chakra-colors-interactive-hover-dark)',
          _focus: {
            bg: 'var(--chakra-colors-background-form-default-dark) !important',
            borderColor: 'var(--chakra-colors-border-form-focus)',
          },
          _hover: {
            bg: 'var(--chakra-colors-interactive-pressed-dark)',
          },
          _invalid: {
            borderColor: 'var(--chakra-colors-border-form-critical-dark) !important ',
            bg: 'var(--chakra-colors-background-form-critial-dark)',
          },
        },
      },
    },
    flushed: {
      field: {
        color: 'var(--chakra-colors-text-base-light)',
        borderColor: 'var(--chakra-colors-border-form-default-light)',
        _hover: {
          shadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-hover-light)',
        },
        _focus: {
          shadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-focus)',
          _hover: {
            shadow: 'none',
            borderColor: 'var(--chakra-colors-border-form-focus)',
          },
        },
        _invalid: {
          shadow: 'none',
          borderColor: 'var(--chakra-colors-border-form-critical-light) !important',
        },

        _dark: {
          color: 'var(--chakra-colors-text-base-dark)',
          borderColor: 'var(--chakra-colors-border-form-default-dark)',
          _hover: {
            shadow: 'none',
            borderColor: 'var(--chakra-colors-border-form-hover-dark)',
          },
          _focus: {
            shadow: 'none',
            borderColor: 'var(--chakra-colors-border-form-focus) ',
          },
          _invalid: {
            shadow: 'none',
            borderColor: 'var(--chakra-colors-border-form-critical-dark) !important',
          },
        },
      },
    },
  },
}
/*
  Example of usage: <NumberInput>

  - Props -
  variants=
    outlined | filled | flushed 

  default variant - outlined 

  size=
    'xs' | 'sm' | 'md' | 'lg'

  Ex:
   1) Basic:
       <NumberInput size='lg' variant='filled' >
          <NumberInputField   />
        </NumberInput>

   @props: 
   https://chakra-ui.com/docs/form/number-input
*/
