import React, { useState, useMemo } from 'react'
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Tfoot,
} from '@chakra-ui/react'
import { TableHeaderText } from 'components'
import { DollarSign, PlusCircle, X } from 'react-feather'
import cloneDeep from 'lodash/cloneDeep'
import round from 'lodash/round'
import { ContinueButton as SetInvoicesContinueButton } from './ContinueButton'
import { Wrapper } from './Wrapper'

export const AddInvoicesPage = () => {
  const inputAmountName = 'amount'
  const defaultInvoice = {
    number: undefined,
    amount: undefined,
  }
  const defaultState = {
    invoices: {
      0: defaultInvoice, // causes adding of invoice inputs to render on init
    },
    showAddNotes: false,
    notes: '',
    errors: [],
  }

  const borderStyle = '1px solid rgba(0, 0, 0, 0.1)'
  const iconStyles = {
    height: '12px',
    width: '12px',
  }
  const noLeftRightPadding = {
    paddingLeft: '0px',
    paddingRight: '0px',
  }

  const [state, setState] = useState(defaultState)

  const addInvoice = () => {
    const newState = cloneDeep(state)
    newState.invoices[Object.keys(state.invoices).length] = defaultInvoice
    setState(newState)
  }

  const addNotes = () => {
    const newState = cloneDeep(state)
    newState.showAddNotes = true
    setState(newState)
  }

  const setNotes = (value: string) => {
    const newState = cloneDeep(state)
    newState.notes = value
    setState(newState)
  }

  const updateInvoice = (index: string, target: any) => {
    const { name, value } = target
    const newState = cloneDeep(state)
    const invoices = newState?.invoices

    if (invoices[index]) {
      const newValue = Math.abs(Number(value))
      invoices[index] = {
        ...invoices[index],
        [name]: newValue,
      }
    }

    setState(newState)
  }

  const removeInvoice = (index: string) => {
    const newState = cloneDeep(state)
    const invoices = newState?.invoices

    if (invoices[index]) {
      delete invoices[index]
    }

    setState(newState)
  }

  const removeLeadingZero = (amountValue) => {
    if (!amountValue) {
      return undefined
    }
    return amountValue
  }

  const total = useMemo(() => {
    return Object.entries(state.invoices).reduce((acc, current) => {
      const amount = current[1][inputAmountName] ?? 0
      acc += amount
      return acc
    }, 0)
  }, [state.invoices])

  return (
    <Wrapper headingText="Enter invoices to pay">
      <TableContainer>
        <Table id="add-invoice-table" variant="unstyled" style={{ tableLayout: 'fixed' }}>
          <Thead borderBottom={borderStyle}>
            <Tr>
              <Th style={noLeftRightPadding}>
                <TableHeaderText>Invoice</TableHeaderText>
              </Th>
              <Th style={noLeftRightPadding}>
                <TableHeaderText>Payment Amount</TableHeaderText>
              </Th>
            </Tr>
          </Thead>

          <Tbody id="invoices">
            {Object.entries(state.invoices).map(([key]) => {
              return (
                <Tr key={key} id={`invoice-${key}`} borderTop={borderStyle}>
                  <Td
                    width="50%"
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '10px',
                    }}
                  >
                    <Input
                      id={`number-${key}`}
                      type="number"
                      name="number"
                      size="sm"
                      placeholder="Invoice No."
                      value={removeLeadingZero(state.invoices[key].number)}
                      onChange={(e) => {
                        updateInvoice(key, e.target)
                      }}
                    />
                  </Td>
                  <Td width="50%" style={noLeftRightPadding}>
                    <HStack>
                      <InputGroup>
                        <InputLeftElement
                          height="100%"
                          pointerEvents="none"
                          children={<Icon as={DollarSign} color="#999999" style={iconStyles} />}
                        />
                        <Input
                          id={`amount-${key}`}
                          type="number"
                          name={inputAmountName}
                          size="sm"
                          placeholder="Amount"
                          textAlign="left"
                          value={removeLeadingZero(state.invoices[key].amount)}
                          onChange={(e) => {
                            updateInvoice(key, e.target)
                          }}
                        />
                      </InputGroup>
                      <IconButton
                        id={`remove-invoice-${key}`}
                        aria-label="Remove Invoice"
                        color="text.critical"
                        border="1"
                        float={'right'}
                        style={{
                          ...noLeftRightPadding,
                          marginLeft: '0px',
                        }}
                        fontSize="md"
                        icon={<Icon as={X} height={'16px'} width={'16px'} />}
                        size="md"
                        variant="iconGhosted"
                        onClick={() => {
                          removeInvoice(key)
                        }}
                      />
                    </HStack>
                  </Td>
                </Tr>
              )
            })}

            <Tr borderTop={borderStyle} borderBottom={borderStyle}>
              <Td float={'left'} style={noLeftRightPadding}>
                <Button
                  color="text.hyperlink.light"
                  id="add-another-invoice"
                  onClick={addInvoice}
                  size={'sm'}
                  fontSize={'14px'}
                  style={{ padding: '6px, 12px, 6px, 12px' }}
                  variant="secondaryOutline"
                  marginBottom={'100px'}
                >
                  <PlusCircle style={iconStyles} /> &nbsp; Add another Invoice
                </Button>
              </Td>
              <Td id="empty-element-needed-for-mobile"></Td>
            </Tr>

            <Tr display={state.showAddNotes ? 'table-row' : 'none'}>
              <Td width={'100%'} colSpan={2} style={noLeftRightPadding}>
                <Textarea
                  id="notes"
                  placeholder="Please add notes about the payment here (e.g., Credit Memos, reasons for over/under payment, etc.)"
                  onChange={(e) => {
                    setNotes(e.target.value)
                  }}
                  value={state.notes}
                  fontSize="14px"
                />
              </Td>
            </Tr>

            <Tr>
              <Td float={'left'} style={noLeftRightPadding}>
                <Text color="text.secondary.light" fontSize={'12px'}>
                  Total Payment
                </Text>
              </Td>
              <Td textAlign={'right'} style={noLeftRightPadding}>
                <Text
                  id="total-payment"
                  color={'text.base.light'}
                  fontSize={'14px'}
                  fontWeight={'400'}
                  lineHeight={'135%'}
                >
                  ${total ? round(total, 2) : 0}
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td float={'left'} style={noLeftRightPadding}>
                {!state.showAddNotes && (
                  <Button
                    color="text.hyperlink.light"
                    id="add-notes"
                    onClick={addNotes}
                    size={'sm'}
                    fontSize={'12px'}
                    style={{ padding: '6px, 12px, 6px, 12px' }}
                    variant="secondaryOutline"
                  >
                    <PlusCircle style={iconStyles} /> &nbsp; Add note
                  </Button>
                )}
              </Td>
              <Td textAlign={'right'} style={noLeftRightPadding}>
                <SetInvoicesContinueButton state={state} />
              </Td>
            </Tr>
          </Tbody>

          <Tfoot></Tfoot>
        </Table>
      </TableContainer>
    </Wrapper>
  )
}
