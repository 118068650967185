import { Box, Text, Image, Flex } from '@chakra-ui/react'
import React from 'react'
import { Images } from 'images'

export class ErrorBoundary extends React.Component<{ children: any }, { hasError: boolean }> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  // eslint-disable-next-line
  componentDidCatch(error, _info) {
    // broadcast error from here if desired
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex direction="column" alignItems="center" padding="1em">
          <Box>
            <Image src={Images.error} />
          </Box>
          <Box mt="3em">
            <Text>Sorry, the application ran into an issue. The issue has been logged. Please try again.</Text>
          </Box>
        </Flex>
      )
    }
    return this.props.children
  }
}
