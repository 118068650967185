export const Table = {
  parts: ['caption', 'td', 'th'],
  baseStyle: {
    caption: {
      mt: 0,
    },
  },
  sizes: {
    sm: {
      th: {
        'font-size': '0.875rem !important',
      },
      td: {
        'font-size': '0.875rem !important',
      },
    },
  },
}
