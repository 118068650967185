export const colors = {
  primary: '#FFCF00',
  secondary: '#262626',
  brand: {
    '50': '#FFFAE5',
    '100': '#FFF2B8',
    '200': '#FFE98A',
    '300': '#FFE15C',
    '400': '#FFD82E',
    '500': '#FFCF00',
    '600': '#CCA600',
    '700': '#997C00',
    '800': '#665300',
    '900': '#332900',
  },
  yellow: {
    '50': '#FFFAE5',
    '100': '#FFF2B8',
    '200': '#FFE98A',
    '300': '#FFE15C',
    '400': '#FFD82E',
    '500': '#FFCF00',
    '600': '#CCA600',
    '700': '#997C00',
    '800': '#665300',
    '900': '#332900',
  },
  grey: {
    '50': '#F2F2F2',
    '100': '#DBDBDB',
    '200': '#C4C4C4',
    '300': '#ADADAD',
    '400': '#969696',
    '500': '#808080',
    '600': '#666666',
    '700': '#4D4D4D',
    '800': '#333333',
    '900': '#1A1A1A',
  },
  green: {
    '50': '#e0fee9',
    '100': '#b9f4c8',
    '200': '#90eda7',
    '300': '#65e484',
    '400': '#3cdc62',
    '500': '#23c348',
    '600': '#189837',
    '700': '#0d6c26',
    '800': '#034215',
    '900': '#001801',
  },
  onfleet: '#AC7AF7',
  page: {
    bg: '#f1f2f6',
  },
  /* Redesigned Color Palette
   * See https://nabis.atlassian.net/wiki/spaces/ENGINEERIN/pages/1229193231/Color
   * For more information.
   */
  services: {
    nabis: '#FFCF00',
    onfleet: '#AC7AF7',
  },
  background: {
    global: { light: '#f2f2f2', dark: '#1a1b1c' },
    container: { light: '#fff', dark: '#2f2f2f' },
    nestedContainer: { light: '#f5f5f5', dark: '#3f3f3f' },
    form: {
      default: { light: '#fff', dark: '#2f2f2f' },
      disabled: { light: '#f5f5f5', dark: '#444' },
      critical: { light: '#f9e1e2', dark: '#5a3434' },
    },
  },
  border: {
    transparent: { light: 'rgba(0,0,0,0.1)', dark: 'rgba(255,255,255,0.2)' },
    opaque: { light: '#e4e4e4', dark: '#464646' },
    form: {
      default: { light: '#e4e4e4', dark: '#6a6a6a' },
      hover: { light: '#ccc', dark: '#8a8a8a' },
      focus: '#548bde',
      critical: { light: '#dc2d2d', dark: '#ff3535' },
    },
  },
  overlay: {
    blackout: { light: 'rgba(0,0,0,0.7)', dark: 'rgba(0,0,0,0.8)' },
  },
  interactive: {
    hover: {
      light: 'rgba(0,0,0,0.05)',
      dark: 'rgba(255,255,255,0.05)',
    },
    pressed: {
      light: 'rgba(0,0,0,0.1)',
      dark: 'rgba(255,255,255,0.1)',
    },
    activate: '#548bde',
  },
  text: {
    base: { light: '#231f20', dark: '#fff' },
    secondary: { light: '#999', dark: '#999' },
    tertiary: { light: '#ccc', dark: '#595959' },
    hyperlink: { light: '#548BDE', dark: '#7db1ff' },
    critical: '#DC2D2D',
    white: '#fff',
    black: '#231f20',
  },
  icon: {
    default: '#999',
    success: '#34B450',
  },
  button: {
    primary: {
      default: { light: '#ffcd00', dark: '#ffcd00' },
      hover: { light: '#f3c400', dark: '#f3c400' },
      focus: { light: '#e1b500', dark: '#31b500' },
    },
    secondary: {
      default: { light: '#fff', dark: '#2f2f2f' },
      hover: { light: '#f4f4f4', dark: '#424242' },
      focus: { light: '#ececec', dark: '#575757' },
    },
    destroy: {
      default: '#dc2d2d',
      hover: '#c81919',
      focus: '#b21616',
    },
    disabled: { dark: '#dc2d2d', light: '#ff3838' },
  },
  accent: {
    green: {
      bg: { light: '#b7f4b5', dark: '#30672F' },
      fg: { light: '#30672f', dark: '#AEFFAC' },
    },
    red: {
      bg: { light: '#ffdcdc', dark: '#672F2F' },
      fg: { light: '#922f2f', dark: '#FFA5A5' },
    },
    orange: {
      bg: { light: '#ffdbc0', dark: '#674A2F' },
      fg: { light: '#905722', dark: '#FFD8B5' },
    },
    yellow: {
      bg: { light: '#ffea7d', dark: '#675A2F' },
      fg: { light: '#958325', dark: '#FFEB82' },
    },
    blue: {
      bg: { light: '#dceaff', dark: '#2F4C67' },
      fg: { light: '#2f4c67', dark: '#C7E4FF' },
    },
    gray: {
      bg: { light: 'rgba(0,0,0,0.1)', dark: 'rgba(255,255,255,0.15)' },
      fg: { light: 'rgba(0,0,0,0.5)', dark: 'rgba(255,255,255,0.8)' },
    },
    black: {
      bg: { light: '#141414', dark: '#2F4C67' },
      fg: { light: '#ffffff', dark: '#C7E4FF' },
    },
  },
}
